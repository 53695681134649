import React from 'react';

const NotFoundPage = () => {
  return (
    <div>Page Not Found</div>
  );
};


export default NotFoundPage;

